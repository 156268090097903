

























import {Component, Vue} from 'vue-property-decorator'
import {VAvatar, VBadge, VBtn, VIcon, VImg, VList, VListItem, VListItemContent, VListItemIcon, VListItemTitle, VMenu} from 'vuetify/lib'
import {userPropicURL} from '~/utils/misc'
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quickmenu for user menu shortcuts on the toolbar
|
*/
@Component({
    components: {
        VMenu,
        VBtn,
        VBadge,
        VList,
        VListItem,
        VListItemIcon,
        VListItemContent,
        VListItemTitle,
        VAvatar,
        VImg,
        VIcon
    },
    computed: {}
})
export default class ToolbarUser extends Vue {

    get avatar() {
        return userPropicURL(this.$auth.memberId, 100)
    }

    clickLogout() {
        this.$store.dispatch('user/userLogout')
    }
}
