








import {Component, Prop, Vue} from 'vue-property-decorator'

/**
 * Not suggested to use this component within popup due to height issue
 */
@Component({})
export default class DiedMascot extends Vue {
    @Prop({default: ''}) title: string
    //  size for width & height
    @Prop({default: '400px'}) size: string

    @Prop(Boolean) bag: boolean
}
