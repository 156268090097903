import {BlogPost} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

export default class BlogData {
    static blogDict: { [key: string]: BlogPost } = {}

    static async updateBlog(blogId: string, blog?: BlogPost): Promise<BlogPost> {
        if (blog) {
            this.blogDict[blogId] = blog
        } else {
            const res = await createRequest(`/blog/blog-post/${blogId}`, 'get').send()
            this.blogDict[blogId] = res.data.blog_post
        }
        return this.blogDict[blogId]
    }

    static getBlog(blogId: string): BlogPost {
        return this.blogDict[blogId]
    }

    static async shouldGetBlog(blogId: string): Promise<BlogPost> {
        let blog = this.getBlog(blogId)
        if (!blog)
            blog = await this.updateBlog(blogId)
        return blog
    }
}
