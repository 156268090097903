





















































    /*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
    import {Component, Prop, Vue} from 'vue-property-decorator'
    import {VIcon, VListGroup, VListItem, VListItemContent, VListItemIcon, VListItemTitle} from 'vuetify/lib'
    import {toAdminv} from '~/utils/misc'

    @Component({
        components: {
            VListItem,
            VListItemIcon,
            VListItemTitle,
            VListItemContent,
            VListGroup,
            VIcon
        }
    })
    export default class NavMenuItem extends Vue {
        @Prop({
            default: () => {
            }
        }) menuItem: any
        @Prop(Boolean) subgroup: boolean

        get link() {
            if (!this.menuItem.items && !this.menuItem.link) {
                return this.menuItem.route ? this.menuItem.route.path : ''
            } else {
                return this.menuItem.link
            }
        }

        clickItem(e) {
            e.preventDefault()
            if (/adminv/.test(this.link)) {
                toAdminv(this.link.replace('/adminv', ''))
            } else {
                this.$router.push(this.link)
            }
        }
    }
