




























import {Component, Prop, Vue} from 'vue-property-decorator'
import NavMenuItem from '~/components/Navigation/NavMenuItem.vue'

/*
|---------------------------------------------------------------------
| Navigation Menu Component
|---------------------------------------------------------------------
|
| Multi-layer navigation menu
|
| menu: [{ text: 'Menu Levels',
|    items: [
|      { text: 'Menu Levels 2.1' },
|      { text: 'Menu Levels 2.2',
|        items: [
|          { text: 'Menu Levels 3.1' },
|          { text: 'Menu Levels 3.2' }
|        ]
|      }
|    ]
|  }]
|
*/

@Component({
    components: {
        NavMenuItem
    }
})
export default class NavMenu extends Vue {
    @Prop({default: () => []}) menu: []
}
