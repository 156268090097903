











import _ from 'lodash'
import {Component, Prop, Vue} from 'vue-property-decorator'
import NavMenu from '~/components/Navigation/NavMenu.vue'
import {VList} from 'vuetify/lib'

@Component({
    components: {
        NavMenu,
        VList
    }
})
export default class MainMenu extends Vue {
    @Prop({default: () => []}) menu: any[]

    get filteredMenu() {
        const newMenu: any[] = []
        for(const levelOne of this.menu) {
            const newMenu2: any[] = []
            for (const levelTwoItem of levelOne.items) {
                if (!!levelTwoItem.items) {
                    const navItems2 = _.filter(levelTwoItem.items, levelThreeItem => {
                        return this.isPermitted(levelThreeItem)
                    })
                    if(navItems2.length) {
                        newMenu2.push({
                            ...levelTwoItem,
                            items: navItems2
                        })
                    }
                } else {
                    if(this.isPermitted(levelTwoItem)) {
                        newMenu2.push(levelTwoItem)
                    }
                }
            }

            if(newMenu2.length) {
                newMenu.push({
                    ...levelOne,
                    items: newMenu2
                })
            }
        }

        return newMenu
    }

    isPermitted(navItem) {
        return _.has(navItem, 'route.meta.permission') ? this.checkPermissions(navItem.route.meta.permission) : true
    }
}
