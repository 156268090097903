








import {Component, Vue} from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
    //  META DATA

    //  UI DATA

    //  DATA

}
